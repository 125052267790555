@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
code,
* {
  @apply font-poppins;
}

body {
  @apply bg-[#F5F5F5] text-textColor text-sm;
}

/* // loader animation start */

@layer utilities {
  .animation-delay-200 {
    animation-delay: 0.2s;
  }
  .animation-delay-400 {
    animation-delay: 0.4s;
  }
}

.circle {
  @apply w-4 h-4 my-12 mx-1 bg-primary rounded-full;
}

/* // loader animation end */

.custom-otp-input {
  background: rgba(0, 112, 243, 0.05);
  text-align: center;
  border: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 0.375rem;
  outline: none;
  padding: 0.625rem 0.625rem;
  width: 2em !important;
}

.custom-otp-input:focus {
  border-color: #2a9d8f;
  box-shadow: 0 0 0 2px rgba(42, 157, 143, 0.5);
}

/* // table start */
.table-border tr th:first-child {
  @apply rounded-l-lg;
}

.table-border tr th:last-child {
  @apply rounded-r-lg;
}
/* // table end */

/* // nested menu start */

.nested-menu {
  @apply relative;
}
.nested-menu > div > ul {
  @apply absolute left-[60px] top-[-45px] w-auto h-auto rounded-lg transition-all whitespace-nowrap max-h-[250px] overflow-y-auto scroll-design z-[9999];
}

.nested-menu > div > ul > li {
  @apply flex basis-full flex-wrap bg-[#90cfc7] font-medium text-primary transition-all w-full pr-3  relative before:absolute;
}
.nested-menu > div > ul > li > a {
  @apply !leading-[25px] text-white block;
}

.nested-menu > div > ul > li.active a {
  @apply before:w-1  before:h-7 before:top-0 before:bg-white before:left-0 before:rounded-r-lg;
}

.nested-menu > div {
  @apply opacity-0 -translate-x-4  transition-all duration-300 ease-linear z-20 absolute;
}

.nested-menu:hover > div {
  @apply opacity-100 translate-x-0 transition-all duration-300 ease-linear w-full;
}

.nested-menu > div > ul {
  @apply w-0;
}
.nested-menu:hover > div > ul {
  @apply w-auto;
}

/* // nested menu end */

/* // scroll bar design start */

.scroll-design::-webkit-scrollbar {
  @apply w-0;
}

.scroll-design:hover::-webkit-scrollbar {
  @apply w-1;
}

.scroll-design:hover::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-design:hover::-webkit-scrollbar-thumb {
  @apply bg-black/20;
}

.scroll-design-x::-webkit-scrollbar {
  @apply h-2;
}

.scroll-design-x::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-design-x::-webkit-scrollbar-thumb {
  @apply bg-black/20;
}

.scroll-design-main::-webkit-scrollbar {
  @apply w-1.5;
}

.scroll-design-main::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-design-main::-webkit-scrollbar-thumb {
  @apply bg-primary  rounded-full;
}

/* // scroll bar design end */

.tablebody:before {
  content: "-";
  display: block;
  line-height: 1em;
  color: transparent;
}

/* phone select design start */

.phone-select .PhoneInputCountry {
  @apply w-[4rem] h-auto relative flex text-sm;
}

.phone-select .PhoneInputInput {
  @apply w-full flex-grow;
}

.phone-select .PhoneInputCountryIcon {
  @apply block w-9 z-30 bg-white;
}

.phone-select .PhoneInputInput {
  @apply focus:outline-none;
}

.phone-select .PhoneInputCountrySelect {
  @apply block absolute inset-0 z-10 focus:outline-none;
}

.phone-select select.PhoneInputCountrySelect {
  @apply bg-white;
}

.phone-select select.PhoneInputCountrySelect option {
  @apply text-black;
}

/* Phone select 2 */
.phone-select2 .special-label {
  @apply text-sm font-medium pb-1.5 first-letter:capitalize;
}

.phone-select2 .form-control {
  @apply border rounded-md p-2 w-full focus:outline-none;
}

/* phone select design end */

/* view user detail img card css starts */

.profile-img-card {
  @apply grid grid-cols-3 lg:grid-cols-4;
}

.profile-img-card > .profile-img {
  @apply col-span-3 first:row-span-6 first:pl-0 lg:pl-5;
}

.profile-img-card > .profile-img:first-child {
  grid-column: auto;
  align-items: start;
}
/* view user detail img card css ends */
/* chat design starts */

.chatbtns .active {
  @apply !bg-primary;
}

.contact-cards .active {
  @apply bg-white;
}

/* chat css  */

.EmojiPickerReact {
  @apply !absolute bottom-10 -left-3;
}

/* notification css */
.notification-links .active {
  @apply !text-white !bg-primary;
}

.ck-content {
  min-height: 140px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.ck > h2 {
  @apply font-semibold text-xl;
}
.ck > h3 {
  @apply font-semibold text-lg;
}

.ck > h4 {
  @apply font-semibold text-base;
}

.ck ul,
ol {
  margin: 10px;
  padding: 5px;
}

.job-responsibilty-list h2 {
  @apply mt-1 mb-1 font-semibold text-xl;
}

.job-responsibilty-list h3 {
  @apply mt-1 mb-1 font-semibold text-lg;
}

.job-responsibilty-list h4 {
  @apply mt-1 mb-1  text-base font-semibold;
}

.job-responsibilty-list ul {
  @apply mt-1 mb-1 list-inside list-disc  text-sm;
}

.job-responsibilty-list ol {
  @apply mt-1 mb-1 list-inside list-decimal text-sm;
}

.job-responsibilty-list {
  @apply mt-1 mb-1;
}

.geocoder-container input {
  border: 1px solid rgba(177, 175, 175, 0.4) !important;
  border-radius: 4px !important;
  width: 100% !important;
  height: inherit !important;
}
.react-date .react-datepicker-wrapper,
.react-date .react-datepicker__input-container {
  @apply !block;
}

.PhoneInputInput {
  @apply focus:outline-none;
}
.geocoder-container input {
  border: 1px solid rgba(177, 175, 175, 0.4) !important;
  border-radius: 4px !important;
  width: 100% !important;
  height: inherit !important;
}

.media-class > div > video {
  @apply !w-[280px] !object-cover !aspect-video !rounded-md;
}


.PhoneInputCountryIcon{
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  margin-top: -5px;
}

.react-datepicker-wrapper{
  width: 100% !important;
}

.dark{
  background-color: white !important;
}


input#session-time {
  display: inline-block;
  position: relative;
}

.icon-time{
  position: absolute;
  top: 10;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: transparent;
  cursor: pointer;
  height: auto;
  position: absolute;  
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  width: auto;
}


